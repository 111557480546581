import React from 'react';
import { Trans } from 'react-i18next';
import classes from './Header.module.scss';
import DrawerMenu from '../DrawerMenu/DrawerMenu';
import { useDispatch, useSelector } from 'react-redux';
import { setMessages, setMode, setQuickResponses } from '../../store/actions/messages';
import { setWidgetIsOpen, setPrechatStatus, setSessionId, setResetChat, setDrawerIsOpen, setEndChat } from '../../store/actions/general';
import MuiPlaceholder from '../controls/MuiPlaceholder/MuiPlaceholder';
import { resetPrechat } from '../../store/actions/prechat';

const Header = ({socket}) => {
  const { showForm, isPrechatSubmitted } = useSelector(state => state.prechat);
  const { prechatStatus, muiFontLoaded, endChat } = useSelector(state => state.general);
  const dispatch = useDispatch();

  const minimizeWidget = () => {
    dispatch(setWidgetIsOpen(false));
  };

  const closeWidget = () => {
    dispatch(setWidgetIsOpen(false));
    if(endChat) {
      dispatch(setDrawerIsOpen(false));
      dispatch(setResetChat(null));
      dispatch(setEndChat(false));
      dispatch(setMode("bot"));
      dispatch(setSessionId(''));
      dispatch(setMessages([]));
      dispatch(setQuickResponses([]));
      dispatch(setPrechatStatus(true));
      dispatch(resetPrechat());
    }
  };

  const backButtonHandler = () => {
    dispatch(resetPrechat());
  }

  return (
    <div className={classes.Header} style={{background: window.sutherland_variables.ui.header_background_color}}>
      <div className={classes.HeaderTitle}>
        {prechatStatus && showForm && !isPrechatSubmitted ? 
          <div className={classes.BackIconButton} onClick={backButtonHandler}>
            <button>
                <MuiPlaceholder
                isMuiFontLoaded={muiFontLoaded}
                element={<div>arrow_back_ios</div>}
                width={80}
                height={80}
                backgroundColor={'transparent'} />
            </button>
            Back
          </div>
        : window.sutherland_variables.ui.header_image_url ? (
          <div className={classes.Image}>
            <img
              src={window.sutherland_variables.ui.header_image_url}
              alt='Logo'
              style={{
                width: window.sutherland_variables.ui.header_image_size,
              }}
            />
            <Trans i18nKey="header.title">
              {window.sutherland_variables.ui.header_title}
            </Trans>
          </div>
        ) : (
          <div>
            <Trans i18nKey="header.title">
              {window.sutherland_variables.ui.header_title}
            </Trans>
          </div>
        )}
        <div className={classes.HeaderButtons}>
          {!prechatStatus && <DrawerMenu socket={socket}/> }
          <button className={classes.IconButton} onClick={minimizeWidget}>
            <MuiPlaceholder
              isMuiFontLoaded={muiFontLoaded}
              element={<div>remove</div>}
              width={30}
              height={30}
              backgroundColor={'#eeeeee'} />
          </button>
          <button className={classes.IconButton} onClick={closeWidget}>
            <MuiPlaceholder
              isMuiFontLoaded={muiFontLoaded}
              element={<div>close</div>}
              width={30}
              height={30}
              backgroundColor={'#eeeeee'} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
