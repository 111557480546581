import React from 'react';
import classes from './FeedbackModal.module.scss';
import Modal from '../Modal/Modal';

const FeedbackModal = ({isOpen, closeHandler}) => {

    const thumbClick = (isThumbsUp, metric) => {
        return { isThumbsUp, metric }
    }

    return (
        <Modal isOpen={isOpen} onClose={closeHandler} contentWidth={'60%'}>
            <div className={classes.FeedbackModalContainer}>
                <h2>We value your feedback</h2>
                <div className={classes.FeedbackModalContent}>
                    <div className={classes.Ratings}>
                        <div className={classes.Label}>How was your chat experience?</div>
                        <div className={classes.Thumbs}>
                            <div className={classes.ThumbsUp} onClick={thumbClick(true, 'experience')}/>
                            <div className={classes.ThumbsDown} onClick={thumbClick(false, 'experience')} />
                        </div>
                    </div>
                    <div className={classes.Ratings}>
                        <div className={classes.Label}>Did we solve your problem?</div>
                        <div className={classes.Thumbs}>
                            <div className={classes.ThumbsUp} onClick={thumbClick(true, 'solve')}/>
                            <div className={classes.ThumbsDown} onClick={thumbClick(false, 'solve')} />
                        </div>
                    </div>
                    <div className={classes.Buttons}>
                        <button className={classes.ButtonFeedback} onClick={closeHandler}>Send feedback</button>
                        <button className={classes.ButtonSkip} onClick={closeHandler}>Skip</button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default FeedbackModal;
