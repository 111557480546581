import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../../components/Avatar/Avatar';
import FileTransfer from '../MessageBubbles/FileTransfer/FileTransfer';
import Incoming from '../MessageBubbles/Incoming/Incoming';
import IncomingAgent from '../MessageBubbles/IncomingAgent/IncomingAgent';
import Outgoing from '../MessageBubbles/Outgoing/Outgoing';
import OrderStatus from '../MessageBubbles/OrderStatus/OrderStatus';
import AdvisorTool from '../MessageBubbles/AdvisorTool/AdvisorTool';
import SystemMessage from '../MessageBubbles/SystemMessage/SystemMessage';
import classes from '../MessageList.module.scss';

function MessageBubble(props) {
    const components = window.sutherland_variables.components;
    const { index, message, isTop, isBottom, timeStamp, socket, avatarSize, avatarUrl, endChat } = props
    // eslint-disable-next-line 
    const [fileuploadEnabled, setFileuploadEnabled] = useState(components["fileupload"]["enabled"])
    const cards = {
        "text_bot": <Incoming message={message} isTop={isTop} isBottom={isBottom} timeStamp={timeStamp} />,
        "text_user": <Outgoing message={message} isTop={isTop} isBottom={isBottom} timeStamp={timeStamp}/>,
        "text_agent": <IncomingAgent message={message} isTop={isTop} isBottom={isBottom} timeStamp={timeStamp} />,
        "file-transfer": <FileTransfer socket={socket} url={message.url} index={index} />,
        "order-status": <OrderStatus socket={socket} parameters={message.parameters} index={index} />,
        "system-advisor": <AdvisorTool socket={socket} index={index} />,
        "status": <SystemMessage message={message} />
    }

    const showAvatar = ["text_bot", "text_agent"]
    const type = (message.type === 'text') ? `text_${message.mode}` : message.type;

    if ((type === "file-transfer" && endChat) || (type === "file-transfer" && !fileuploadEnabled) || (type === "order-not-found"))
      return <></>
      
    const avatarContainer = type !== "status" ? <div
            className={classes.avatarContainer}
            style={{ 
              minWidth: avatarSize, 
              height: isBottom ? avatarSize : 0, 
              paddingTop: timeStamp === '' ? '5px' : '40px'}}
          >
            {showAvatar.includes(type) && isBottom ? (
              <Avatar
                isAgent={message.mode === "agent"}
                height={avatarSize}
                width={avatarSize}
                pictureUrl={avatarUrl}
              />
            ) : null}
          </div> : null

    return <>
      {avatarContainer}
      {cards[type]}
    </>
}

MessageBubble.propTypes = {
  index: PropTypes.number,
  message: PropTypes.object,
  isTop: PropTypes.bool,
  isBottom: PropTypes.bool,
  timeStamp: PropTypes.string,
  socket: PropTypes.object,
  avatarSize: PropTypes.number,
  avatarUrl: PropTypes.string,
  endChat: PropTypes.bool
};

export default MessageBubble;
