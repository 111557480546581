import React from 'react';
import PropTypes from 'prop-types';
import classes from './Typing.module.scss';

const Typing = (props) => {
  if (props.show) {
    if (props.mode === 'dot-loader') {
      return <div className={classes.TypingLoader} />
    }
    return <div className={classes.Typing}>
      {props.typingText}
    </div>;
  }
  return null
};

Typing.propTypes = {
  show: PropTypes.bool,
  mode: PropTypes.string,
  typingText: PropTypes.string
};

export default Typing;
