import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../../models/Message';
import { addMessage, setQuickResponses, setIsTyping } from '../../store/actions/messages';
import classes from './QuickResponseContainer.module.scss';
import QuickResponseButton from './QuickResponseButton/QuickResponseButton';

const QuickResponseContainer = ({socket}) => {
  const { sessionId } = useSelector(state => state.general);
  const { mode, quickResponses } = useSelector(state => state.messages);
  const dispatch = useDispatch();
  
  const renderQuickResponses = () => {
    if (quickResponses && quickResponses.length > 0) {
      return <div>{quickResponses.map((quickResponse, index) => (
        <QuickResponseButton
          key={index}
          quickResponse={quickResponse}
          activateResponse={() => {
            dispatch(addMessage(new Message({
              text: quickResponse.text,
              mode: 'user'
            })));
            dispatch(setQuickResponses([]));
            socket.emit("send", {
              message: quickResponse.text,
              session_id: sessionId,
              mode: mode,
              messenger_id: window.sutherland_variables.botId
            });
            dispatch(setIsTyping(true))
          }}
        />
      ))}
      </div>
    }
  };

  return <div className={classes.QuickResponse}>
    {renderQuickResponses()}
  </div>;
};

export default QuickResponseContainer;
