import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classes from './Text.module.scss';

const Text = (props) => {
  const textareaRef = useRef(null);
  const {showError, errorMessage} = props;
  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      props.onEnter(textareaRef);
    }
  };

  return (
    <div className={showError ? classes.TextError : classes.Text}>
      <textarea 
        value={props.value}
        onChange={(e) => props.onChange(e.target.value, textareaRef)}
        placeholder={props.placeholder}
        onKeyDown={onKeyDown}
        rows={props.rows ? props.rows : 2}
        maxLength={props.maxLength}
        name={props.name}
        ref={textareaRef}
        disabled={props.disabled}
      />
      {showError ? (
        <div className={classes.ErrorMessage}>{errorMessage}</div>
      ) : null}
    </div>
  );
};

Text.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onEnter: PropTypes.func,
  name: PropTypes.string,
  maxLength: PropTypes.number,
  showError: PropTypes.bool,
  errorMessage: PropTypes.string,
  rows: PropTypes.number
};

export default Text;
