import React from 'react';

const Skeleton = (props) => {
    const { display, width, height, backgroundColor } = props;
    return <div className="skeleton" style={{
        display: display ? display : 'block',
        width: width,
        height: height,
        backgroundColor: backgroundColor,
        borderRadius: 3
    }}></div>;
}

export default Skeleton;
