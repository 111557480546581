import React from 'react';
import PropTypes from 'prop-types';
import systemMessageModule from './SystemMessage.module.scss';

function SystemMessage(props) {
  return <div className={systemMessageModule.SystemMessageContainer}>
    <div>
      {props.message.text}
    </div>
  </div>;
}

SystemMessage.propTypes = {
  message: PropTypes.object,
};

export default SystemMessage;