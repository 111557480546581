import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Input from '../controls/Input/Input';
import classes from './CustomForm.module.scss';

function CustomForm({ formData, buttonHandler, handleFieldChange }) {
  const renderInput = (field) => (
    <div className='mb-3' key={field.name}>
      <Input
        type={field.type}
        value={field.value}
        onChange={handleFieldChange}
        placeholder={`${field.text}${field?.required ? '*' : ''}`}
        name={field.name}
        showError={field.isError}
        errorMessage={field.errorMsg}
        rows={field.rows || 1}
        disabled={field.disabled}
      />
    </div>
  );

  return (
    <div className={classes.CustomForm}>
      <form>
        {formData?.cardDescription && <p className='mb-3'>
          <ReactMarkdown 
            children={formData.cardDescription} 
            remarkPlugins={[remarkGfm]} 
            linkTarget="_blank"
          />
        </p>}
        {formData?.fields?.map(renderInput)}
        {formData?.submitText && <button className='mt-1' type="button" onClick={buttonHandler} disabled={formData.submitBtnDisabled}>{formData.submitText}</button>}
      </form>
    </div>
  );
}

CustomForm.propTypes = {
  formData: PropTypes.shape({
    cardDescription: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.object),
    submitText: PropTypes.string,
  }),
  buttonHandler: PropTypes.func,
  handleFieldChange: PropTypes.func,
};

export default CustomForm;