import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import messages from './reducers/messages';
import general from './reducers/general';
import prechat from './reducers/prechat';
import authentication from './reducers/authentication';
import { setSessionId } from '../store/actions/general';
import { setMessages, setMode, setQuickResponses } from '../store/actions/messages';
import { setResetChat, setDrawerIsOpen, setWidgetIsOpen, setEndChat, setPrechatStatus } from '../store/actions/general';
import { resetPrechat } from '../store/actions/prechat';

const component = document.getElementsByTagName("sutherland-messenger")[0];
const botId = component.getAttribute('bot-id');
const configKey = `${window.location.hostname}_${botId}_root`

const persistConfig = {
  key: configKey,
  storage
};

const rootReducer = combineReducers({
  messages: messages,
  general: general,
  prechat: prechat,
  authentication: authentication,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(persistedReducer, applyMiddleware(ReduxThunk));

store.subscribe(() => {
  // Check persist timeout
  const state = store.getState()
  const currentDate = new Date(); // Get the current date and time
  const sessionStart = new Date(state.general.sessionStartTime);
  const timeDifference = currentDate.getTime() - sessionStart.getTime(); // Calculate the difference in milliseconds
  const secondsElapsed = timeDifference / 1000; // Convert milliseconds to seconds
  const sessionExpirySeconds = 60 * 60 * 3; // 3 hours

  if (secondsElapsed > sessionExpirySeconds) {
    console.log('\u25B6 Chat session expired, restarting...');
    store.dispatch(setResetChat(true));
    store.dispatch(setWidgetIsOpen(false));
    store.dispatch(setDrawerIsOpen(false));
    store.dispatch(setEndChat(false));
    store.dispatch(setMode("bot"));
    store.dispatch(setSessionId(''));
    store.dispatch(setMessages([]));
    store.dispatch(setQuickResponses([]));
    store.dispatch(setPrechatStatus(true));
    store.dispatch(resetPrechat());
  }
});

export const persistor = persistStore(store);
