import React, { useEffect, useRef, useCallback } from 'react';
import { Trans } from 'react-i18next';
import classes from './MessageList.module.scss';
import MessageBubble from './MessageBubbles/MessageBubble';
import SpinnerLoader from '../../components/SpinnerLoader/SpinnerLoader';
import { useSelector } from 'react-redux';

const MessageList = (props) => {
  const { messages, mode } = useSelector(state => state.messages);
  const { avatarUrl, agentName, endChat } = useSelector(state => state.general);
  const messageListBottom = useRef(null);

  const scrollToBottom = useCallback(() => {
      messageListBottom.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    scrollToBottom()
  }, [messages, scrollToBottom])

  const renderMessageBubbles = () => {
    const orientation = messages.map((item, index, array) => {
      return {
        modeType: item.modeType,
        isTop: index === 0 || array[index - 1].modeType !== item.modeType,
        isBottom: index === array.length - 1 || array[index + 1].modeType !== item.modeType
      };
    });

    messages.forEach((item, index, arr) => {
      if (item.type === "order-status" && arr.slice(index + 1).some(obj => obj.type !== "order-not-found")) {
            item.parameters = { ...item.parameters, isDisable: true };
        }
    });

    return messages.map((message, index) => {
      const avatarSize = 45;
      const { isTop, isBottom } = orientation[index];
      const messageTimestamp = !message.utcTime ? '' : new Date(message.utcTime).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
      });
      const messageBubbleOuterClasses = [classes.messageBubbleOuter];
      
      if (isBottom) {
        messageBubbleOuterClasses.push('mb-3');
      }

      if (message.type === "file-transfer" && message.parameters?.fileTransfer === true) {
        messageBubbleOuterClasses.push(classes.messageBubbleOuterHidden);
      }

      return (
        <div className={messageBubbleOuterClasses.join(' ')} key={message.id}>
          <MessageBubble 
            index={index} 
            message={message} 
            isTop={isTop} 
            isBottom={isBottom} 
            timeStamp={messageTimestamp} 
            socket={props.socket} 
            avatarSize={avatarSize}
            avatarUrl={avatarUrl}
            endChat={endChat}/>
        </div>
      );
    });
  };

  return <div className={classes.content}>
    {mode === 'agent' && agentName && 
      <div className={classes.agentConnected}>
        <Trans i18nKey="container.chattingWithAgent">
          Currently chatting with agent:
        </Trans>
        <strong>{agentName}</strong>
      </div>
    }
    <div className={classes.MessageList}>
      <SpinnerLoader />
      {renderMessageBubbles()}
    </div>
    <div ref={messageListBottom}></div>
  </div>
};

export default MessageList;