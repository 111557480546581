import React, { useReducer, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { patchMessages } from '../../../../store/actions/messages';
import classes from './AdvisorTool.module.scss';

const customStyles = {
  control: (provided) => ({
    ...provided,
    "&:focused": {
      borderColor: '#0092C8 !important',
      outline: '#0092C8 !important'
    },
    "&:hover": {
      backgroundColor: 'rgba(153, 211, 233, 0.31) !important',
      border: '1px solid #D3D3D3 !important',
      outline: '#D3D3D3 !important'
    },
    "&.dropdown-indicator":{
      color: '#000 !important'
    }
  }),
  menu: (provided) => ({
    ...provided,
    maxHeight: '110px !important',
    height: '110px !important',
    "&>div": {
      height: '110px !important',
      overflowY:'scroll !important'
    }
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: 0
  }) 
};

var isSuthMobile = {
  Android: function() {
      return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function() {
      return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function() {
      return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function() {
      return navigator.userAgent.match(/IEMobile/i);
  },
  any: function() {
      return !!(isSuthMobile.Android() || isSuthMobile.BlackBerry() || isSuthMobile.iOS() || isSuthMobile.Opera() || isSuthMobile.Windows());
  }
};

function AdvisorTool({ socket, index }) {
  const { messages } = useSelector(state => state.messages);
  const manufacturerRef = useRef();
  const productLineRef = useRef();
  const modelNumberRef = useRef();
  const fieldsData = [
    { name:"manufacturer", text: "Manufacturer" },
    { name:"productLine", text: "Product Line" },
    { name:"modelNumber", text: "Model Number" }
  ]

  const fieldToParameterMap = {
    manufacturer: "manufacturerLabel",
    productLine: "productLineLabel",
    modelNumber: "modelNumberLabel",
  };

  const initialState = {
    fields: fieldsData.map((field) => {
      const parameterKey = fieldToParameterMap[field.name];
      const label = messages[index]?.parameters[parameterKey] || '';
      return {
        ...field,
        options: [],
        value: messages[index]?.parameters[field.name] || "",
        disabled: messages[index]?.parameters?.advisorToolStatus ? true : false,
        label: label,
        menuOpen: false
      };
    }),
    submitBtnDisabled: true,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'UPDATE_FIELD_VALUE':
      case 'UPDATE_FIELD_OPTIONS':
      case 'UPDATE_DISABLED_VALUE':
      case 'SET_MENU_OPEN':
        return {
          ...state,
          fields: state.fields.map(field =>
            field.name === action.field
              ? {
                  ...field,
                  value: action.type === 'UPDATE_FIELD_VALUE' ? action.value : field.value,
                  options: action.type === 'UPDATE_FIELD_OPTIONS' ? action.value : field.options,
                  disabled: action.type === 'UPDATE_DISABLED_VALUE' ? action.value : field.disabled,
                  menuOpen: action.type === 'SET_MENU_OPEN' ? action.value : field.menuOpen
                }
              : field
          ),
        };
      case 'UPDATE_STATE_VALUE':
        return {
          ...state,
          [action.field] : action.value
        }
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const dispatchAdvisorToolStatus = useDispatch();

  const fetchOptions = useCallback((fieldName, parameter="") => {
    const baseUrl = window.sutherland_variables.bot_engine.api_url;
    let apiUrl = `${baseUrl}/api/feature/micron/manufacturer`;
    if(fieldName === "productLine") {
      apiUrl = `${baseUrl}/api/feature/micron/productLine?manufacturer=${parameter}`;
    } else if(fieldName === "modelNumber"){
      apiUrl = `${baseUrl}/api/feature/micron/model?id=${parameter}&projectId=${window.sutherland_variables.project_id}`;
    }
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        let updatedOptions = []
        if (Array.isArray(data)) {
          updatedOptions = data.map(result => ({
            label: result.label,
            value: result.value,
          }));
        }
        dispatch({ type: 'UPDATE_FIELD_OPTIONS', field: fieldName, value: updatedOptions });
      })
      .catch(error => {
        console.error('Error fetching manufacturers:', error);
      });
  }, []);

  useEffect(() => {
    fetchOptions('manufacturer');
  }, [fetchOptions]);

  const openMenu = (fieldName, isOpen) => {
    const fields = ['manufacturer', 'productLine', 'modelNumber'];

    fields.forEach((name) => {
      const show = (name === fieldName) ? isOpen : false
      dispatch({ type: 'SET_MENU_OPEN', field: name, value: show });
    })
  }

  const handleFieldChange = (field, value) => {
    dispatch({ type: 'UPDATE_FIELD_VALUE', field, value });
    const { manufacturer, productLine, modelNumber } = state.fields.reduce(
      (acc, curr) => {
        acc[curr.name] = curr.value;
        return acc;
      },
      {}
    );
    if(field === "manufacturer" && value && manufacturer!==value) {
      if(productLine!=="") {
        dispatch({ type: 'UPDATE_FIELD_VALUE', field:'productLine', value:'' });
        dispatch({ type: 'UPDATE_FIELD_OPTIONS', field:'productLine', value:[] });
        productLineRef.current?.clearValue();
      }
      if(modelNumber!=="") {
        dispatch({ type: 'UPDATE_FIELD_VALUE', field:'modelNumber', value:'' });
        dispatch({ type: 'UPDATE_FIELD_OPTIONS', field:'modelNumber', value:[] });
        modelNumberRef.current?.clearValue();
      }
      fetchOptions('productLine', value)
      dispatch({ type: 'UPDATE_STATE_VALUE', field: "submitBtnDisabled", value: true });
      openMenu('manufacturer', false);
      manufacturerRef.current?.blur();
    } else if(field === "productLine" && value && productLine!==value){
      if(modelNumber!=="") {
        dispatch({ type: 'UPDATE_FIELD_VALUE', field:'modelNumber', value:'' });
        dispatch({ type: 'UPDATE_FIELD_OPTIONS', field:'modelNumber', value:[] });
        modelNumberRef.current?.clearValue();
      }
      fetchOptions('modelNumber', value);
      dispatch({ type: 'UPDATE_STATE_VALUE', field: "submitBtnDisabled", value: true });
      openMenu('productLine', false);
      productLineRef.current?.blur();
    } else if(field === "modelNumber" && value !== "") {
      dispatch({ type: 'UPDATE_STATE_VALUE', field: "submitBtnDisabled", value: false });
      dispatch({ type: 'SET_MENU_OPEN', field: 'modelNumber', value: false });
      openMenu('modelNumber', false);
      modelNumberRef.current.blur();
    }
  };

  function findLabelByValue(data, value) {
    const foundItem = data.find(item => item.value === value);
    return foundItem ? foundItem.label : "";
  }

  const buttonHandler = () => {
    const manufacturer = state.fields.find(field => field.name === 'manufacturer').value;
    const productLine = state.fields.find(field => field.name === 'productLine').value;
    const modelNumber = state.fields.find(field => field.name === 'modelNumber').value;

    const manufacturerOptions = state.fields.find(field => field.name === 'manufacturer').options;
    const productLineOptions = state.fields.find(field => field.name === 'productLine').options;
    const modelNumberOptions = state.fields.find(field => field.name === 'modelNumber').options;

    socket.emit('send', {
      name: 'system-advisor',
      parameters: {
        "AdvisorURL": modelNumber,
        "Manufacturer": findLabelByValue(manufacturerOptions,manufacturer),
        "ProductLine": findLabelByValue(productLineOptions,productLine),
        "Model": findLabelByValue(modelNumberOptions,modelNumber)
      },
    });
    state.fields.forEach(field => {
      dispatch({ type: 'UPDATE_DISABLED_VALUE', field: field.name, value: true });
    });
    dispatch({ type: 'UPDATE_STATE_VALUE', field: "submitBtnDisabled", value: true });

    const messagesList = {
      index : index,
      key: "parameters",
      value: { 
        ...fieldsData,
        advisorToolStatus: true,
        manufacturer: manufacturer,
        productLine: productLine,
        modelNumber: modelNumber,
        manufacturerLabel: findLabelByValue(manufacturerOptions,manufacturer),
        productLineLabel: findLabelByValue(productLineOptions,productLine),
        modelNumberLabel: findLabelByValue(modelNumberOptions,modelNumber),
      }
    }
    dispatchAdvisorToolStatus(patchMessages(messagesList));
  };

  return (
    <div className={classes.AdvisorToolForm}>
      <form>
        {state.fields.map(field => (
          <div key={field.name} className='mb-3'>
              <Select
                styles={customStyles}
                isSearchable={!isSuthMobile.any()}
								classNamePrefix='react-select'
								options={field.options}
								ref={field.name === "manufacturer" ? manufacturerRef : field.name === "productLine" ? productLineRef : field.name === "modelNumber" ? modelNumberRef : null}
								onChange={e => handleFieldChange(field.name, e?.value)}
                onBlur= {() => {
                  setTimeout(() => {
                    openMenu('', false);  
                  }, 100)
                }}
								placeholder={field.text}
								noOptionsMessage={() => `No ${field.text}`}
                onFocus={(e) => {
                  setTimeout(() => {
                    openMenu(field.name, true);  
                  }, 100)
                }}
                isDisabled={field.disabled}
								defaultValue={field.value !== "" ? { label: field.label, value: field.value } : null}
                menuIsOpen={field.menuOpen}
              />

          </div>
        ))}
        <div className='mt-1'>
          <button
            onClick={buttonHandler}
            type='button'
            disabled={state.submitBtnDisabled}
          >
            Submit
          </button>
        </div>
      </form>
     </div>
  );
}

AdvisorTool.propTypes = {
  socket: PropTypes.object
};

export default AdvisorTool;