import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import classes from './PrechatCategory.module.scss';
import Input from '../../components/controls/Input/Input';
import { setPrechatStatus } from '../../store/actions/general';
import { updateCategoryFormData, updateCategoryState, resetPrechat } from '../../store/actions/prechat';

const PrechatCategory = () => {
    const dispatch = useDispatch();
    const { widgetIsOpen } = useSelector(state => state.general);
    const { showForm, formData, selectedCategory, showError, agentAvailable, isPrechatSubmitted, selectedCategoryLabel } = useSelector(state => state.prechat);
    const baseUrl = window.sutherland_variables.bot_engine.api_url;
    const url = new URL(window.location.href);
    const urlParams = new URLSearchParams(window.location.search);
    const domain = url.hostname;
    let region = urlParams.get('bot_region');
    let projectId = "1";

    region = !region ? '' : region.toLowerCase();
    
    if (domain.includes('uk.') || region === 'uk') {
      projectId = "2";
    } else if (domain.includes('eu.') || region === 'eu') {
      projectId = "3";
    }

    window.sutherland_variables.project_id = projectId;
    
    useEffect(() => {
      if(selectedCategory) {
        if (selectedCategory === 'upgrade' || selectedCategory === 'order') {
            dispatch(updateCategoryState({ field: 'agentAvailable', value: true }));
        } else {
            fetch(`${baseUrl}/api/feature/salesforce/availability?projectId=${projectId}`)
            .then(response => response.json())
            .then(data => {
                const datares = data.messages[0].message.results[0].isAvailable;
                dispatch(updateCategoryState({ field: 'agentAvailable', value: datares ? true: false }));
            })
            .catch(err => {
                console.log(err);
            });
        }
      }
    }, [selectedCategory, dispatch, baseUrl,projectId]);

    const checkAvailability = (categoryKey, categoryLabel) => {
        dispatch(updateCategoryState({ field: 'showError', value: false }));
        dispatch(updateCategoryState({ field: 'selectedCategory', value: categoryKey }));
        dispatch(updateCategoryState({ field: 'selectedCategoryLabel', value: categoryLabel }));
        if (categoryKey !== 'coupon_code') {
            dispatch(updateCategoryState({ field: 'showForm', value: true }));
        } else {
            dispatch(setPrechatStatus(false));
        }
    }
  
    const handleInputChange = (field, value) => {
      dispatch(updateCategoryFormData({ field: field, value: value }));
    };
  
    const isValidEmail = (email) => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    };
    

    const handleContinueButtonClick = async () => {
      let isError = !formData.firstName || !formData.lastName || !isValidEmail(formData.email);
      if (!agentAvailable && !formData.describe)
      {
        isError = true
      }
      dispatch(updateCategoryState({field: 'showError', value: isError }));
      if(!isError) {
        if(agentAvailable) {
          dispatch(setPrechatStatus(false));
        }
       else {
          const dataForm = {
            name: formData.firstName + " " + formData.lastName,
            email__c: formData.email,
            description: formData.describe,
            category__c: selectedCategoryLabel,
            projectId: projectId,
            SCAN_ID__c: "",
          }
          await fetch(`${baseUrl}/api/feature/salesforce/offline`, {
            method: 'POST',
            body: JSON.stringify(dataForm),
            headers: {
              'Content-Type': 'application/json',
            },
          }); 
          dispatch(updateCategoryState({field: 'isPrechatSubmitted', value: true }));
       }
      }
   
    };

    const handleAskButtonClick = () => {
      dispatch(resetPrechat());
      dispatch(updateCategoryState({ field: 'agentAvailable', value: true }));
    }
  
    const formCategories = [
      { key: 'upgrade', label: 'I\u2019d like help finding a compatible upgrade' },
      { key: 'order', label: 'I have questions about my order' },
      { key: 'coupon_code', label: 'I\u2019m looking for a promo code for Crucial.com' },
      { key: 'troubleshooting', label: 'I have installation or troubleshooting questions' },
      { key: 'returns', label: 'I would like to return product from my recent Crucial.com order', displayLabel: 'I\u2019d like to return product from my recent order' },
      { key: 'warranty', label: 'I need to make a warranty claim' },
      { key: 'online_account', label: 'I have questions about my online account' },
      { key: 'benefits', label: 'I\u2019d like to discuss benefits for organizations and resellers', displayLabel: 'I\u2019d like to discuss small business benefits' },
      { key: 'site_problem', label: 'I am reporting a problem with your website' },
    ];

    const categorySection = (
      <>
        <p>Thank you for contacting Crucial support. To make sure you get the right help, please select an option from the list below:</p>
        <ul className={classes.categoryLabel}>
        {formCategories.map(category => (
          <li key={category.key}>
            <button key={category.key} onClick={() => checkAvailability(category.key, category.label)}>
              {category.displayLabel ? category.displayLabel : category.label}
            </button>
          </li>
        ))}
        </ul>
      </>
    )

    const randerInput = (type, fieldName, placeholder, isError = false, errorMessage = '', rows = 1) => {
      return (
        <div className='mb-4'>
        <Input
            type={type}
            value={formData[fieldName]}
            onChange={handleInputChange}
            placeholder={placeholder}
            name={fieldName}
            showError={isError 
              && ((fieldName==="email" && !isValidEmail(formData.email)) ? 
              true : 
              fieldName==="partNumber" || fieldName==="orderNumber" ? false 
              : formData[fieldName] === "" ? true : false)
            }
            errorMessage={errorMessage}
            rows={rows}
          />
        </div>
      )
    }

    const formSection = (
      <>
        {agentAvailable && <p className='mt-3'>Great. Before we begin, we need a little bit of info.</p>}
        {!agentAvailable && (
            <>
              <p className='mb-3 mt-3'>
                <Trans i18nKey="prechat.agentOffline">
                 Our agents are not available right now; our contact center is open Monday through Friday, 7:00 am to 6:00 pm Mountain Time.
                </Trans></p>
              <p>Please complete the form below and we will get back to you as soon as we can.</p>
            </>
        )}
                
        <form>
          {randerInput('text','firstName','First name<span>*</span>', showError, 'First name is required.')}
          {randerInput('text','lastName','Last name<span>*</span>', showError, 'Last name is required.')}
          {randerInput('Email','email','Email<span>*</span>', showError, 'Email address is not a valid email address.')}
          {agentAvailable && ((selectedCategory === "order") ?
            randerInput('text','orderNumber','Order number', false, '') :
            randerInput('text','partNumber','Part number', false, ''))
          }
          {!agentAvailable && 
            randerInput('textarea','describe','Please describe your issue<span>*</span>', showError, 'Enter a description for your issue.',6)
          }
          {agentAvailable && showForm && <button type="button" onClick={handleContinueButtonClick}>Continue</button>}
          {!agentAvailable && showForm && <button type="button" onClick={handleContinueButtonClick}>Send message</button>}
        </form>
      </>
    )

    const submittedFromSection = (
      <>
        <div>
            <p className='mt-3'>Thank you.</p>
            <p className='mt-3'>Our agents have received your message and will contact you as soon as possible, generally within 1 business day.</p>
            <p className='mt-3'>You may now close this window, or ask another question.</p>
        </div>
        <div className='mt-4'>
           <button type="button" onClick={handleAskButtonClick}>Ask another question</button>
        </div>
      </>
    )

    const containerClass = widgetIsOpen ? classes.PrechatCategoryForm : classes.PrechatCategoryFormHidden;

    return (
      <div className={containerClass}>
        { isPrechatSubmitted ? submittedFromSection : 
          <>
          {!showForm && categorySection }
          {showForm &&  formSection }
          </>
        }
      </div>
    );
};

export default PrechatCategory;