/* eslint-disable no-undef */
import React, { useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import classes from './DrawerMenu.module.scss';
import { setSessionId } from '../../store/actions/general';
import { setMessages, setMode, setQuickResponses } from '../../store/actions/messages';
import { setResetChat, setDrawerIsOpen, setEndChat, setPrechatStatus } from '../../store/actions/general';
import { resetPrechat } from '../../store/actions/prechat';
import PopOver from '../controls/PopOver/PopOver';
import MuiPlaceholder from '../controls/MuiPlaceholder/MuiPlaceholder';

const DrawerMenu = ({socket}) => {
  const { drawerIsOpen, muiFontLoaded } = useSelector(state => state.general);
  const { sessionId } = useSelector(state => state.general);
  const isMounted = useRef(true);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    }
  }, [])

  const handleOpen = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setDrawerIsOpen(drawerIsOpen ? false : true));
  }, [dispatch, drawerIsOpen]);

  const handleClose = useCallback((e) => {
    if (isMounted.current) {
      dispatch(setDrawerIsOpen(false));
    }
  }, [dispatch]);

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${year}-${day}-${month}`;
  }

  async function downloadFile(blob, fileName) {
    try {
      const a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = fileName;
      a.style.display = "none"; // Hide the anchor
      document.body.appendChild(a); // Add the anchor to the DOM
      a.click(); // Simulate a click to trigger the download
  
      // Clean up
      URL.revokeObjectURL(a.href);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  }

  const downloadTranscript = useCallback((e) => {
    const configHeaders = {
      'Content-Type': 'application/json',
      'bot-config': window.sutherland_variables.botId
    }

    const urlParams = new URLSearchParams(window.location.search);
    let cacheDebug = JSON.parse(urlParams.get('enable_debug'));

    if (cacheDebug) {
      configHeaders['bypass-cache'] = "true";
    }

    fetch(`${window.sutherland_variables.engineBaseUrl}/api/transcript/download/${sessionId}`, {
      method: 'GET',
      headers: configHeaders
    })
    .then(async response => {
      downloadFile(await response.blob(), `Micron_Transcript_${formatDate(new Date())}.pdf`);
    })
    .catch(err => {
      console.error(err);
    });
  },[sessionId])

  const restartChat = useCallback(() => {
    dispatch(setDrawerIsOpen(false))
    dispatch(setResetChat(true))
    dispatch(setEndChat(false))
    dispatch(setMode("bot"))
    dispatch(setSessionId(''))
    dispatch(setMessages([]))
    dispatch(setQuickResponses([]));
    setTimeout(() => {
      socket.disconnect();
    }, 1000);
    dispatch(setPrechatStatus(true));
    dispatch(resetPrechat()); 
  }, [dispatch, socket]);

  const endChat = useCallback(() => {
    setTimeout(() => {
      socket.emit("end-chat", {})
    }, 1000);
  }, [socket]);

  return (
    <div className={classes.DrawerMenu}>
      <button className={classes.IconButton} onClick={handleOpen}>
        <MuiPlaceholder
          isMuiFontLoaded={muiFontLoaded}
          element={<div className={classes.MoreHoriz}>more_horiz</div>}
          width={30}
          height={30}
          backgroundColor={'#eeeeee'} />
      </button>
      <PopOver close={handleClose} opened={drawerIsOpen}>
        <ul className={classes.MenuBox}>
          <li className={classes.MenuItem} onClick={downloadTranscript}>
            <div className={classes.MenuItemIcon}>
              <MuiPlaceholder
                isMuiFontLoaded={muiFontLoaded}
                element={<span aria-hidden="true">download_file</span>}
                width={0}
                height={0}
                backgroundColor={'transparent'} />
            </div>
            <div className="text">
              <Trans i18nKey="drawerMenu.downloadChat">
                Download chat
              </Trans>
            </div>
          </li>
          <li className={classes.MenuItem} onClick={restartChat}>
            <div className={classes.MenuItemIcon}>
              <MuiPlaceholder
                  isMuiFontLoaded={muiFontLoaded}
                  element={<span aria-hidden="true">restart_alt</span>}
                  width={0}
                  height={0}
                  backgroundColor={'transparent'} />
            </div>
            <div className="text">
              <Trans i18nKey="drawerMenu.restartChat">
                Restart chat
              </Trans>
            </div>
          </li>
          <li className={classes.MenuItem} onClick={endChat}>
            <div className={classes.MenuItemIcon}>
              <MuiPlaceholder
                  isMuiFontLoaded={muiFontLoaded}
                  element={<span aria-hidden="true">close</span>}
                  width={0}
                  height={0}
                  backgroundColor={'transparent'} />
            </div>
            <div className="text">
              <Trans i18nKey="drawerMenu.endChat">
                End chat
              </Trans>
            </div>
          </li>
        </ul>
      </PopOver>
    </div>
  );
};
export default DrawerMenu;
