import React, { useReducer, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import CustomForm from '../../../../components/CustomForm/CustomForm';
import { patchMessages } from '../../../../store/actions/messages';

function OrderStatus({ socket, parameters, index }) {
  const { messages } = useSelector(state => state.messages);
  const { formData } = useSelector(state => state.prechat);

  const initialState = {
    ...parameters,
    fields: parameters?.fields.map((field) => ({
      ...field,
      type: field.name === "OrderNumber" ? "text" : field.type,
      errorMsg: field.name === 'EmailOrder' ? 'Please provide a valid email' : 'Please provide an order number',
      isError: false,
      value: messages[index]?.parameters[field.name]? messages[index]?.parameters[field.name] : "",
      disabled: messages[index]?.parameters?.orderStatus? true : false,
    })),
    submitBtnDisabled: messages[index]?.parameters?.orderStatus? true : false,
    cardDescription: messages[index]?.cardDescription ?  messages[index].cardDescription : parameters.cardDescription
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'UPDATE_FIELD_VALUE':
      case 'UPDATE_ERROR_VALUE':
      case 'UPDATE_DISABLED_VALUE':
        return {
          ...state,
          fields: state.fields.map(field =>
            field.name === action.field
              ? {
                  ...field,
                  value: action.type === 'UPDATE_FIELD_VALUE' ? action.value : field.value,
                  isError: action.type === 'UPDATE_ERROR_VALUE' ? action.value : field.isError,
                  disabled: action.type === 'UPDATE_DISABLED_VALUE' ? action.value : field.disabled,
                }
              : field
          ),
        };
      case 'UPDATE_STATE_VALUE':
        return {
          ...state,
          [action.field] : action.value
        }
      case 'UPDATE_CARD_DESCRIPTION':
        return {
          ...state,
          cardDescription : action.value
        }
      default:
        return state;
    }
  };

 

  const [state, dispatch] = useReducer(reducer, initialState);
  const dispatchOrderStatus = useDispatch();

  const handleFieldChange = (field, value) => {
     dispatch({ type: 'UPDATE_FIELD_VALUE', field, value });
  };

  const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const buttonHandler = () => {
    let isSubmit = true

    state.fields.forEach(field => {
      let errorFound = false;
      const trimmedValue = field.value.trim();
      if (field.name === 'EmailOrder' && (trimmedValue === '' || !isValidEmail(trimmedValue))) {
        errorFound = true;
        isSubmit = false;
      }
      if(field.name === 'OrderNumber' && trimmedValue === '') {
        errorFound = true;
        isSubmit = false;
      }
      dispatch({ type: 'UPDATE_ERROR_VALUE', field: field.name, value: errorFound });
    });
  
    if (isSubmit) {
      const EmailOrder = state.fields.find(field => field.name === 'EmailOrder').value;
      const OrderNumber = state.fields.find(field => field.name === 'OrderNumber').value;

      socket.emit('send', {
        name: 'order-status',
        parameters: {
          ...EmailOrder && { "EmailOrder": EmailOrder },
          ...OrderNumber && { "OrderNumber": OrderNumber }
        },
      });
      state.fields.forEach(field => {
        dispatch({ type: 'UPDATE_DISABLED_VALUE', field: field.name, value: true });
      });
      dispatch({ type: 'UPDATE_STATE_VALUE', field: "submitBtnDisabled", value: true });
      
      const messagesList = {
        index : index,
        key: "parameters",
        value: { 
          ...parameters,
          orderStatus: true,
          EmailOrder: EmailOrder,
          OrderNumber: OrderNumber,
          cardDescription: state.cardDescription
        }
      }
      dispatchOrderStatus(patchMessages(messagesList));

      socket.on('message-received', (data) => {
        if(data?.payload[0]?.type==="order-not-found") {
            messages[index].parameters.orderStatus = false;
            messages[index].parameters.EmailOrder = "";
            messages[index].parameters.OrderNumber = "";
            handleFieldChange("EmailOrder", '');
            handleFieldChange("OrderNumber", '');
            dispatch({ type: 'UPDATE_STATE_VALUE', field: "submitBtnDisabled", value: false });
            dispatch({ type: 'UPDATE_DISABLED_VALUE', field: "EmailOrder", value: false });
            dispatch({ type: 'UPDATE_DISABLED_VALUE', field: "OrderNumber", value: false });
            dispatch({ type: 'UPDATE_CARD_DESCRIPTION', value: "We couldn't find that order. Please re-enter your info." });
            const messagesList = {
              index : index,
              key: "parameters",
              value: { 
                ...parameters,
                cardDescription: "We couldn't find that order. Please re-enter your info."
              }
            }
            dispatchOrderStatus(patchMessages(messagesList));
          }
      })
    }
  };

  useEffect(()=>{
    if(!messages[index]?.parameters?.orderStatus && !messages[index+1] && messages[index]?.orderStatus !== true) {
      let desc = "";
      if(!parameters.hasNlpOrderStatusForm) {
        if(formData.orderNumber!=="" && formData.email!=="") {
          desc = "Please confirm or edit the information below to continue."
        }
        if(formData.orderNumber==="") {
          desc = "Please provide the order number:"
        }
        if(formData.orderNumber) {
          handleFieldChange("OrderNumber", formData.orderNumber);
        }
        if(formData.email) {
          handleFieldChange("EmailOrder", formData.email);
        }
      } else {
        desc = "Please provide the following:"
      }
      if(desc !== "") {
        dispatch({ type: 'UPDATE_CARD_DESCRIPTION', value: desc });
      }
    } 
  },[formData.orderNumber, formData.email, messages, index, dispatchOrderStatus, parameters.hasNlpOrderStatusForm, parameters])
  
  useEffect(()=>{
    if(parameters.isDisable) {
      dispatch({ type: 'UPDATE_STATE_VALUE', field: "submitBtnDisabled", value: true });
      dispatch({ type: 'UPDATE_DISABLED_VALUE', field: "EmailOrder", value: true });
      dispatch({ type: 'UPDATE_DISABLED_VALUE', field: "OrderNumber", value: true });
    }
  },[parameters])

  return (
    <CustomForm
      formData={state}
      buttonHandler={buttonHandler}
      handleFieldChange={handleFieldChange}
    />
  );
}

OrderStatus.propTypes = {
  socket: PropTypes.object,
  parameters: PropTypes.object,
};

export default OrderStatus;