import React from 'react';
import PropTypes from 'prop-types';
import outgoingClassesModule from './Outgoing.module.scss';
import messageBubbleClassesModule from '../MessageBubble.module.scss';

function Outgoing(props) {
  const outgoingClasses = [outgoingClassesModule.Outgoing, messageBubbleClassesModule.MessageBubble];
  const configOutgoing = window.sutherland_variables.ui.outgoing_messages;
  const bubbleStyles = configOutgoing ? {
    color: configOutgoing.text_color,
    backgroundColor: configOutgoing.bubble_color,
  } : {}

  if (props.isTop) {
    outgoingClasses.push(outgoingClassesModule.top);
  }
  if (props.isBottom) {
    outgoingClasses.push(outgoingClassesModule.bottom);
  }

  return <div className={outgoingClassesModule.OutgoingContainer}>
    <div className={outgoingClasses.join(' ')} style={bubbleStyles}>
      {props.message.text}
    </div>
    <div className={messageBubbleClassesModule.outgoingTimeStamp}>
      {props.timeStamp}
    </div>
  </div>;
}

Outgoing.propTypes = {
  message: PropTypes.object,
  isTop: PropTypes.bool,
  isBottom: PropTypes.bool,
  timeStamp: PropTypes.string
};

export default Outgoing;
