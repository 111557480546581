import React, { useState, useEffect, useRef } from 'react';
import io from "socket.io-client";
import classes from './MainContainer.module.scss';
import Header from '../../components/Header/Header';
import PrechatCategory from '../PrechatForm/PrechatCategory';
import FeedbackModal from '../../components/FeedbackModal/FeedbackModal';
import MessageComposer from '../../components/MessageComposer/MessageComposer';
import MessageList from '../MessageList/MessageList';
import Typing from '../Typing/Typing';
import QuickResponseContainer from '../QuickResponseContainer/QuickResponseContainer';
import Message from '../../models/Message';
import QuickResponse from '../../models/QuickResponse';
import { setSessionId, setAgentName, setEndChat, setResetChat } from '../../store/actions/general';
import { addMessage, cancelFileUploads, setQuickResponses, setMode, setIsTyping } from '../../store/actions/messages';
import { useDispatch, useSelector } from 'react-redux';

const MainContainer = () => {
  const components = window.sutherland_variables.components;
  const { prechatEnabled, widgetIsOpen, sessionId, resetChat, endChat, prechatStatus } = useSelector(state => state.general);
  const { messages, typing, mode } = useSelector(state => state.messages);
  const { formData, selectedCategory, selectedCategoryLabel, agentAvailable } = useSelector(state => state.prechat);
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  // eslint-disable-next-line
  const [feedbackEnabled, setFeedbackEnabled] = useState(components["feedback"]["enabled"])
  const socketRef = useRef(null);
  const dispatch = useDispatch();
  
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    
    window.sutherland_variables.resetChat = resetChat;
    window.sutherland_variables.prechatStatus = prechatStatus;
    window.sutherland_variables.sessionId = sessionId;
    window.sutherland_variables.mode = mode;
    
    if (!socketRef.current && !prechatStatus && !endChat) {
      let socket = io(window.sutherland_variables.engineBaseUrl, {
        path: '/ws/chat/',
        transports: ['websocket', 'polling'],
        upgrade: true,
        reconnection: true,
        reconnectionAttempts: 10,
        reconnectionDelayMax: 2000, 
        randomizationFactor: 0.5,
        auth: {
          bot_id: window.sutherland_variables.botId, // The bot config ID
          session_id: !window.sutherland_variables.sessionId ? sessionId : window.sutherland_variables.sessionId
        },
        query: {
          bot_id: window.sutherland_variables.botId, // The bot config ID
          session_id: !window.sutherland_variables.sessionId ? sessionId : window.sutherland_variables.sessionId
        }
      });
      socketRef.current = socket;

      socket.on('connected', (data) => {
        const svars = window.sutherland_variables;
        if (!svars.prechatStatus) {
          if (svars.resetChat || svars.resetChat === null) {
              console.log('\u25B6 initialize session');
              if (!window.sutherland_variables.sessionId) {
                dispatch(setSessionId(data.session_id));
                socketRef.current.auth.session_id = data.session_id;
                socketRef.current._opts.query.session_id = data.session_id;
              }
              const projectId = window.sutherland_variables.project_id;
              // Send welcome event to start convo
              console.log('\u25B6 start chat');
              if (selectedCategory === 'upgrade' || selectedCategory === 'order' || selectedCategory === 'coupon_code') {
                socket.emit("send", {
                  name: "welcome-event",
                  parameters:
                    {
                      First_Name__c: formData.firstName, 
                      Last_Name__c: formData.lastName,
                      Email__c: formData.email, 
                      ...formData.orderNumber && { OrderNumber: formData.orderNumber },
                      ...formData.partNumber && { PartNumber: formData.partNumber },
                      Category__c: selectedCategoryLabel,
                      UserAgent: navigator.userAgent,
                      ScreenResolution: `${window.screen.width}x${window.screen.height}`,
                      ProjectId: projectId,
                      ReferrerUri: document.referrer
                    }
                }); 
              } else {
                const requestPayload = {
                  "First_Name__c": formData.firstName,
                  "Last_Name__c": formData.lastName,
                  "Email__c": formData.email,
                  "Category__c": selectedCategoryLabel,
                  "ProjectId": projectId,
                  "ReferrerUri": document.referrer,
                  "ScreenResolution": `${window.screen.width}x${window.screen.height}`
                }
                const baseUrl = window.sutherland_variables.bot_engine.api_url;
                fetch(`${baseUrl}/api/feature/salesforce/start-transfer`,
                {
                  method: 'POST',
                  body: JSON.stringify(requestPayload),
                  headers: {
                    'Content-Type': 'application/json',
                  },
                })
                .then(response => response.json())
                .then(data => {
                  socket.emit("send", {
                    name: "transfer",
                    parameters: data
                  }); 
                })
                .catch(err => {
                    console.log(err);
                });
              }
              dispatch(setResetChat(false))
            }
        } else {
          socket = null;
        }
      });

      socket.on('message-received', (data) => {
        data.mode = data.mode.toLowerCase()
        dispatch(setMode(data.mode))
        dispatch(setIsTyping(false))
        const hasNlpOrderStatusForm = data.payload.some(item => item["nlp-order-status-form"] === true);
        if(hasNlpOrderStatusForm) {
          data.payload = data.payload.filter(item => !item["nlp-order-status-form"]);
        }
        for (const message of data.payload) {
          message.mode = data.mode
          switch(message.type) {
            case 'chips':
              const responsesList = [];
              for (const option of message.options) {
                const newQuickResponse = new QuickResponse(option);
                responsesList.push(newQuickResponse);
              }
              dispatch(setQuickResponses(responsesList));
              break;
            case 'info': 
              if (data.mode === "agent") {
                dispatch(setAgentName(data.username));
              }
              message.text = `[${message.title}](${message.link})`;
              dispatch(addMessage(new Message(message)));
              break;
            case 'status': 
              dispatch(addMessage(new Message(message)));     
              switch (message.event) {
                case "ChatEnd":
                  dispatch(setEndChat(true));
                  // eslint-disable-next-line
                  setTimeout(() => {
                    if (socket) {
                      socket.disconnect()
                    }
                  }, 3000)
                  break;
                case "FileRequestCancel":
                  dispatch(cancelFileUploads())
                  break;
                default:
                  break;
              }
              break;
              case 'order-status': 
                dispatch(setIsTyping(false))
                message.parameters = {
                  cardDescription: message['card-description'],
                  hasNlpOrderStatusForm: hasNlpOrderStatusForm || false,
                  fields: message['fields'],
                  submitText: message['submit-text']
                };
                dispatch(addMessage(new Message(message)));
              break;
            default:
              if (message.type !== 'error' && message.type !== 'liveAgentHandoff') {
                if (data.mode === "agent") {
                  dispatch(setAgentName(data.username));
                }
                dispatch(addMessage(new Message(message)));
              }
              break;
          }
        }
      });

      socket.on('agent-typing', () => {
        dispatch(setMode("agent"))
        dispatch(setIsTyping(true))
        setTimeout(() => {
          dispatch(setIsTyping(false))  
        }, 1500)
      });

      socket.on('stop-typing', () => {
        dispatch(setMode("agent"))
        dispatch(setIsTyping(false))
      });
      
      socket.on('file-transfer', (data) => {
        dispatch(setIsTyping(false))
        const message = {
          "type": "file-transfer",
          "text": "",
          "url": data?.url,
        }
        dispatch(addMessage(new Message(message)));
      });

      setIsLoading(false)
    } else if (prechatStatus) {
      socketRef.current = null;
      setIsLoading(false)
    }
  }, [dispatch, sessionId, messages, mode, resetChat, prechatStatus, endChat, formData, agentAvailable, selectedCategory, selectedCategoryLabel]);
  if (!isLoading) {
    return (
      <div className={prechatEnabled || widgetIsOpen ? classes.MainContainer : classes.MainContainerGrowIn}>
        <Header socket={socketRef.current}/>
        {prechatStatus && prechatEnabled && <PrechatCategory isOpen={true} onClose={handleCloseModal} />}
        <FeedbackModal isOpen={modalOpen && feedbackEnabled} onClose={handleCloseModal} />
        {!prechatStatus &&
        <> 
          <MessageList  socket={socketRef.current} />  
          {!endChat && 
            <>         
              <QuickResponseContainer socket={socketRef.current}/>
              <div className={classes.typeComposerSection}>
                <Typing show={typing} mode="dot-loader" typingText="" />  
                <div className={classes.footer}>
                  <MessageComposer socket={socketRef.current}/>
                </div>
              </div>
            </>
          }
        </>
        }
      </div>
    );
  }
  return <div></div>
};

export default MainContainer;