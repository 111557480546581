import { SET_USER_ID, SET_FINGERPRINT_ID } from '../constants/authentication';

const initialState = {
  userId: null,
  fingerprintId: null
};

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_ID:
      return {
        ...state,
        userId: action.userId
      };

    case SET_FINGERPRINT_ID:
      return {
        ...state,
        fingerprintId: action.fingerprintId
      }

    default:
      return state;
  }
};

export default authentication;
