import { 
  SAVE_PRECHAT, 
  RESET_PRECHAT, 
  UPDATE_CATEGORY_FORM_DATA, 
  UPDATE_CATEGORY_STATE
} from '../constants/prechat';

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  reason: '',
  showForm: false,
  formData: {
    firstName: '',
    lastName: '',
    email: '',
    orderNumber: '',
    partNumber: '',
    describe: '',
  },
  selectedCategory: null,
  selectedCategoryLabel: '',
  showError : false,
  agentAvailable: false,
  isPrechatSubmitted: false,
};

const prechat = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_PRECHAT:
      return {
        ...state,
        firstName: action.firstName,
        lastName: action.lastName,
        email: action.email,
        reason: action.reason,
      };
    case RESET_PRECHAT:
      return initialState;
    case UPDATE_CATEGORY_FORM_DATA:
      return { ...state, formData:
        {
          ...state.formData,
          [action.categoryFormData.field]: action.categoryFormData.value
        }
      };
    case UPDATE_CATEGORY_STATE:
      return { ...state, [action.categoryStateData.field]: action.categoryStateData.value };
    default:
      return state;
  }
};

export default prechat;
