import React from 'react';
import PropTypes from 'prop-types';
import classes from './QuickResponse.module.scss';

const QuickResponseButton = (props) => {
  const activateResponse = () => {
    props.activateResponse(props.quickResponse);
  }

  return (
    <button
      className={classes.QuickResponseButton}
      onClick={activateResponse}
    >
      {props.quickResponse.text}
    </button>
  );
};

QuickResponseButton.propTypes = {
  activateResponse: PropTypes.func,
  quickResponse: PropTypes.object
};

export default QuickResponseButton;
