import React from 'react';
import Skeleton from '../Skeleton/Skeleton';

const MuiPlaceholder = ({element, isMuiFontLoaded, width, height, backgroundColor}) => {
    if (isMuiFontLoaded) {
      return element
    }

    return (
      <Skeleton 
        width={width}
        height={height}
        backgroundColor={backgroundColor}
      />
    )
  }


export default MuiPlaceholder;
