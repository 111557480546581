import React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import classes from './SpinnerLoader.module.scss';

const SpinnerLoader = () => {
    const { messages } = useSelector(state => state.messages);

    if (messages.length === 0) {
        return (<div className={classes.Ripple}>
            <div>
                <div></div><div></div>
                <span>
                <Trans i18nKey="container.loading">
                    Loading
                </Trans>
                </span>
            </div>
        </div>)
    }
    
    return null;
};

export default SpinnerLoader;
