import { Provider } from 'react-redux';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import classes from './App.module.scss';
import RootContainer from './containers/RootContainer/RootContainer';
import { PersistGate } from 'redux-persist/integration/react';
import {store, persistor} from './store/configureStore';

function App() {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      debug: false,
      lng: window.sutherland_variables.language,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
      resources: window.sutherland_variables.i18n
    });
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <div className={classes.App}>
          <RootContainer />
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
